import { v4 as uuidv4 } from 'uuid';

export class UserProjectMappingDto {
  projectId: string = ''; 
  userId: string = uuidv4(); // This will be a GUID
  email: string = ''; 
  meshUsername: string = ''; 
  role: string = '';
  isActive: boolean = true;
  status: UserProjectMappingStatusEnum = UserProjectMappingStatusEnum.Approved;
  creationDateTime: Date = new Date();

  constructor(partial?: Partial<UserProjectMappingDto>) {
    Object.assign(this, partial);
  }
}

export enum UserProjectMappingStatusEnum {
  Rejected = 'Rejected',
  Pending = 'Pending',
  Approved = 'Approved'
}
