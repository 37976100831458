
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ValidationRule } from '../../../../shared/models/validation-rule';
import { BaseApiService } from '../../../../shared/services/_base-api.service';
import { UserProjectMappingDto } from './user-project-mapping.model';
import { NpsStore } from '../../../services/nps.store';
import { NpsConfigurationApiUrl } from '../../shared/api/configuration.service';

export const NpsUserProjectMappingApiUrl = {
  UserProjectMappingGetListByProjectId: (projectId: string) => `/api/projects/profiles/${projectId}/users`,
  UserProjectMappingGetByProjectAndUserId: (projectId: string, userId: string) => `/api/projects/profiles/${projectId}/users/${userId}`,
  UserProjectMappingCreate: (projectId: string) => `/api/projects/profiles/${projectId}/users`,
  UserProjectMappingUpdate: (projectId: string, userId: string) => `/api/projects/profiles/${projectId}/users/${userId}`,
  UserProjectMappingDeleteById: (projectId: string, userId: string) => `/api/projects/profiles/${projectId}/users/${userId}`,
  UserProjectMappingRegister: () => "/api/projects/users/register",
  UserProjectMappingGetList: () => "/api/projects/users"
}

@Injectable({providedIn: 'root'})
export class UserProjectMappingService extends BaseApiService{

  constructor(store: NpsStore) {
    super(store.apiUrl);
  }


  getValidators(method: keyof typeof NpsUserProjectMappingApiUrl) {
    return super.get<ValidationRule[]>(NpsConfigurationApiUrl.ConfigurationValidators(), { method });
  }

  //#region Project Users

  projectMappings(projectId: string = '', userId: string = '', email: string = '', status: string = ''): Observable<UserProjectMappingDto[]> {
    return super.get(NpsUserProjectMappingApiUrl.UserProjectMappingGetList(), { projectId, userId, email, status });
  }

  projectUserRegister(post: UserProjectMappingDto): Observable<UserProjectMappingDto> {
    return super.post(NpsUserProjectMappingApiUrl.UserProjectMappingRegister(), post)
  }
    
  projectUsers(projectId: string): Observable<UserProjectMappingDto[]> {
    return super.get(NpsUserProjectMappingApiUrl.UserProjectMappingGetListByProjectId(projectId));
  }

  projectUserGet(projectId: string, userId: string): Observable<UserProjectMappingDto> {
    return super.get(NpsUserProjectMappingApiUrl.UserProjectMappingGetByProjectAndUserId(projectId, userId));
  }

  projectUserCreate(projectId: string, post: UserProjectMappingDto): Observable<UserProjectMappingDto> {
    return super.post(NpsUserProjectMappingApiUrl.UserProjectMappingCreate(projectId), post)
  }

  projectUserUpdate(projectId: string, userId: string, post: UserProjectMappingDto): Observable<UserProjectMappingDto> {
    return super.put(NpsUserProjectMappingApiUrl.UserProjectMappingUpdate(projectId, userId), post);
  }

  projectUserDelete(projectId: string, userId: string): Observable<UserProjectMappingDto> {
    return super.delete(NpsUserProjectMappingApiUrl.UserProjectMappingDeleteById(projectId, userId));
  }

  //#endregion
}
